<template>
  <div class="mission-map">
    <div class="search-address" v-if="showControl">
      <div style="position: relative; display: inline-block">
        <input
          type="text"
          placeholder="搜索地点"
          v-model="searchKeyword"
          @input="searchAddress"
        />
        <svg
          v-if="searchKeyword !== ''"
          class="icon-font clear-keyword"
          aria-hidden="true"
          @click="
            searchKeyword = '';
            searchList = [];
          "
        >
          <use xlink:href="#icon-menua-jianqu12"></use>
        </svg>
      </div>

      <!--            <button class="btn btn-primary">查询</button>-->
      <div class="search-panel" v-if="searchList.length > 0">
        <p
          v-for="address in searchList"
          :key="`${address.id}_${address.name}`"
          @click="selectAddress(address)"
        >
          {{ address.name }}
        </p>
      </div>
    </div>

    <div
      :id="mapId"
      style="width: 100%; height: 100%; border: 1px solid #ddd"
    ></div>

    <div v-if="showControl" class="map-control">
      <div @click="getCurrentLocation()">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-menudingwei"></use>
        </svg>
      </div>
      <div @click="zoomIn">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-menufangda"></use>
        </svg>
      </div>
      <div @click="zoomOut">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-menusuoxiao1"></use>
        </svg>
      </div>
    </div>

    <button
      v-if="showControl"
      :class="['btn', 'btn-primary', 'btn-save', { disabled: isSubmitting }]"
      @click="saveMission"
    >
      保存
    </button>
    <button
      v-if="showControl"
      :class="['btn', 'btn-primary', 'btn-saves', { disabled: isSubmitting }]"
      @click="clearMission"
    >
      取消
    </button>
  </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
import { coordConvertionUrl } from "@/requestUrl";

export default {
  name: "projectionComp",
  props: {
    onlyMap: {
      type: Boolean,
      default: false,
    },
    mapId: {
      type: String,
      default: "Map",
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
    showControl: {
      type: Boolean,
      default: false,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
    mapPlugin: {
      type: Array,
      default: () => ["AMap.AutoComplete", "AMap.PlaceSearch"],
    },
    mapKey: {
      type: String,
      default: "6ca9eeedb102d4cb92e7cd64f3258001",
    },
    defaultName: String,
    flight: [String, Number],
    back: [String, Number],
    defaultWayPoints: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      map: null,
      searchKeyword: "",
      AMap: null,
      searchList: [],
      points: [], // 存储选中的点
      currentLocationMarker: null,
      polylines: [], //存储折线图

      name: "",
      flightHeight: "",
      courseReversalHeight: "",
      wayPoints: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.name = this.defaultName ?? "";
      this.flightHeight = this.flight ?? "";
      this.courseReversalHeight = this.back ?? "";
      this.initMap();
    });
  },
  methods: {
    /**
     * 坐标转换
     * @param {Number} type 坐标类型
     * @param {Number | String} latitude 纬度
     * @param {Number | String} 经度
     * @param {String} returnType 想要返回的坐标类型
     * */
    coordConvertion({
      type = 1,
      latitude,
      longitude,
      returnType = "wgs84",
    } = {}) {
      return this.$fly
        .post(coordConvertionUrl, {
          type,
          latitude,
          longitude,
        })
        .then((res) => {
          if (res?.code === 0) {
            return res.data[returnType].split(",");
          }
          return [longitude, latitude];
        })
        .catch(() => [longitude, latitude]);
    },
    async initMap() {
      const AMap = await this.loadAMap();
      const map = new AMap.Map(this.mapId);
      // 这是郑投科技园的坐标
      const DEFAULT_COORDS = [113.533891, 34.691876];
      if (!this.isPreview) {
        map.on("rightclick", (e) => {
          this.mapRightClickHandler(AMap, map, e);
        });
      }

      this.map = map;
      this.AMap = AMap;

      if (this.defaultWayPoints.length > 0) {
        const firstCoords = await this.coordConvertion({
          longitude: this.defaultWayPoints[0][0] || "",
          latitude: this.defaultWayPoints[0][1] || "",
          type: 2,
          returnType: "gcj02",
        });
        this.getCurrentLocation(firstCoords, 15, false);

        for (let index in this.defaultWayPoints) {
          let coords = this.defaultWayPoints[index];
          coords = await this.coordConvertion({
            longitude: coords[0],
            latitude: coords[1],
            type: 2,
            returnType: "gcj02",
          });
          const marker = this.addMarker({
            AMap,
            coords,
            content: `
                            <div class="custom-marker">
                                <img src="/common/static/img/mapIcon/marker.png" alt="">
                                <div>
                                    ${
                                      this.isPreview
                                        ? `<div class="active">${
                                            this.points.length + 1
                                          }</div>`
                                        : '<svg class="active icon" aria-hidden="true"><use xlink:href="#icon-menubiaodianshangdecha"></use></svg>'
                                    }

                                </div>
                            </div>
                        `,
            eventHandler: (e, marker) => {
              if (this.isPreview) {
                return;
              }
              const index = this.points.indexOf(marker);
              this.wayPoints.splice(index, 1);
              this.points.splice(index, 1);
              map.remove(marker);
              this.polylines.forEach((polyline) => {
                map.remove(polyline);
              });
              this.polylines = [];
              let path = [];
              this.points.forEach((point, index) => {
                point.setContent(`
                             <div class="custom-marker">
                                <img src="/common/static/img/mapIcon/marker.png" alt="">
                                <div>
                                    <svg class="active icon" aria-hidden="true">
                                        <use xlink:href="#icon-menubiaodianshangdecha"></use>
                                    </svg>
                                </div>
                            </div>
                        `);
                const { position } = point.getOptions();
                path.push(new AMap.LngLat(position.lng, position.lat));
              });
            },
          });
          this.points.push(marker);
          this.wayPoints.push(coords);
          let lastPoint = this.defaultWayPoints[index - 1];
          if (!lastPoint) {
            continue;
          }
          lastPoint = await this.coordConvertion({
            longitude: lastPoint[0],
            latitude: lastPoint[1],
            type: 2,
            returnType: "gcj02",
          });
          const path = [
            new AMap.LngLat(lastPoint[0], lastPoint[1]),
            new AMap.LngLat(coords[0], coords[1]),
          ];
        }
      } else {
        this.getCurrentLocation(DEFAULT_COORDS, 15);
      }
    },
    changeField(type, val) {
      this.$emit("changeField", {
        type,
        val,
      });
    },
    async saveMission() {
      if (this.isSubmitting) {
        this.$vc.message("请不要重复提交");
        return;
      }
      const wayPoints = [];
      for (let index in this.wayPoints) {
        let item = this.wayPoints[index];
        wayPoints[index] = await this.coordConvertion({
          longitude: item[0],
          latitude: item[1],
        });
      }
      this.changeField("wayPoints", wayPoints);
      if (this.wayPoints.length == 0) {
        this.$vc.message("请选择位置");
        return;
      }
      this.$emit("onSave");
    },
    /**
     * 取消按钮
     */
    clearMission() {
      this.$emit("onSave");
    },
    /**
     * 获取当前在地图位置
     * @param {Array} defaultCoords 坐标点
     * @param {Number} mapIndex 缩放等级
     * @param {Boolean} isDefault 是否是默认中心点
     * */
    async getCurrentLocation(defaultCoords, mapIndex = 15, isDefault = true) {
      const coords = defaultCoords ||
        (await this.getGeolocation()) || [116.397428, 39.90923];
      const mapCenter = await this.convertFrom(AMap, [coords]);
      this.map.setCenter(mapCenter);
      if (this.currentLocationMarker) {
        this.map.remove(this.currentLocationMarker);
      }
      this.map.setZoom(mapIndex);
      if (isDefault) {
        this.currentLocationMarker = this.addMarker({
          AMap,
          coords: mapCenter,
        });
      }
    },
    zoomIn() {
      this.map.zoomIn();
    },
    zoomOut() {
      this.map.zoomOut();
    },
    /**
     * 地图鼠标右击处理
     * */
    mapRightClickHandler(AMap, map, ev) {
      if (this.points.length >= 1) {
        this.$vc.message("设置失败，请先删除上一个点");
        return;
      }
      const { lng, lat } = ev.lnglat;
      const coords = [lng, lat];
      const marker = this.addMarker({
        AMap,
        coords,
        content: `
                    <div class="custom-marker">
                        <img src="/common/static/img/mapIcon/marker.png" alt="">
                        <div>
                            
                            <svg class="active icon" aria-hidden="true">
                                <use xlink:href="#icon-menubiaodianshangdecha"></use>
                            </svg>
                        </div>
                    </div>
                `,
        eventHandler: (e, marker) => {
          const index = this.points.indexOf(marker);
          this.wayPoints.splice(index, 1);
          this.points.splice(index, 1);
          map.remove(marker);
          this.polylines.forEach((polyline) => {
            map.remove(polyline);
          });
          this.polylines = [];
          let path = [];
          this.points.forEach((point, index) => {
            point.setContent(`
                             <div class="custom-marker">
                                <img src="/common/static/img/mapIcon/marker.png" alt="">
                                <div>
                                   
                                    <svg class="active icon" aria-hidden="true">
                                        <use xlink:href="#icon-menubiaodianshangdecha"></use>
                                    </svg>
                                </div>
                            </div>
                        `);
            const { position } = point.getOptions();
            path.push(new AMap.LngLat(position.lng, position.lat));
          });
        },
      });
      this.points.push(marker);
      this.wayPoints.push(coords);
    },

    /**
     * 选择地点
     * @param {Object} address 地点信息
     * */
    selectAddress(address) {
      const { name, location } = address;
      this.searchKeyword = name;
      this.map.setCenter([location.lng, location.lat]);
      this.map.setZoom(18);
      if (this.currentLocationMarker) {
        this.map.remove(this.currentLocationMarker);
      }
      this.currentLocationMarker = this.addMarker({
        AMap: this.AMap,
        coords: [location.lng, location.lat],
      });
      this.searchList = [];
    },
    // 搜索地址
    searchAddress(e) {
      const autoComplete = new this.AMap.AutoComplete({
        city: "全国",
      });
      autoComplete.search(e.target.value, (status, res) => {
        this.searchList = res.tips ?? [];
      });
    },
    // 加载地图
    loadAMap() {
      return AMapLoader.load({
        key: this.mapKey, // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: this.mapPlugin, // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        AMapUI: {
          // 是否加载 AMapUI，缺省不加载
          version: "1.1", // AMapUI 缺省 1.1
          plugins: [], // 需要加载的 AMapUI ui插件
        },
      })
        .then((AMap) => {
          return AMap;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // 获取坐标
    getGeolocation() {
      if (navigator.geolocation) {
        return new Promise((resolve) => {
          navigator.geolocation.getCurrentPosition((position) => {
            const { latitude, longitude } = position.coords;
            resolve([longitude, latitude]);
          });
        });
      } else {
        return [116.397428, 39.90923];
      }
    },
    // 坐标转换为高德地图坐标
    convertFrom(AMap, coords, type = "gps") {
      return new Promise((resolve) => {
        coords.map(([lng, lat]) => new AMap.LngLat(lng, lat));
        AMap.convertFrom(coords, type, (status, result) => {
          if (result.info === "ok") {
            const { lat, lng } = result.locations[0];
            resolve([lng, lat]);
          }
        });
      });
    },
    /**
     * 添加标记点
     * @param {Object} AMap 地图对象
     * @param {Array} coords 经纬度坐标
     * @param {String} content 标记点内容
     * @param {Object} icon 自定义图标
     * @param {Function} eventHandler 事件处理
     * */
    addMarker({ AMap, coords, content, icon, eventHandler }) {
      let options = {
        position: new AMap.LngLat(...coords), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        title: "",
        offset: [-15, -20],
      };
      if (content) {
        options.content = content;
      }
      if (icon) {
        options.icon = icon;
      }
      const marker = new AMap.Marker(options);
      if (eventHandler) {
        marker.on("rightclick", (e) => {
          eventHandler(e, marker);
        });
      }
      this.map.add(marker);
      return marker;
    },
  },
};
</script>

<style lang="stylus" scoped>
.mission-map {
  height: 100%;
  width: 100%;
  position: relative;

  .search-address {
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 1;

    .clear-keyword {
      position: absolute;
      right: 30px;
      top: 50%;
      font-size: 20px;
      width: 18px;
      height: 18px;
      color: #999;
      transform: translateY(-50%);
    }

    input {
      width: 220px;
      height: 30px;
      border-radius: 3px;
      border: 1px solid #999;
      padding: 5px 10px;
      margin-right: 20px;
      vertical-align: middle;
    }

    .btn {
      height: 30px;
      padding: 0 12px;
      vertical-align: middle;
    }

    .search-panel {
      position: absolute;
      left: 0;
      top: 34px;
      background: #fff;
      padding: 8px;
      border-radius: 5px;
      border: 1px solid #999;

      p {
        cursor: pointer;
        margin-bottom: 0;

        &:hover {
          background: #ddd;
        }

        &:not(:last-of-type) {
          margin-bottom: 10px;
        }
      }
    }
  }

  .map-control {
    position: absolute;
    right: 20px;
    bottom: 30px;
    z-inde: 1;

    & > div {
      background: #00B694;
      width: 40px;
      height: 40px;
      border-radius: 5px;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
      font-size: 22px;
      text-align: center;
      line-height: 40px;
      cursor: pointer;

      &:not(:last-of-type) {
        margin-bottom: 10px;
      }
    }
  }

  .mission-info {
    position: absolute;
    right: 0;
    top: 0;
    width: 684px;
    // height 80px
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 0.8);
    z-index: 1;
    padding: 10px 20px;
    font-size: 14px;
    line-height: 1;

    &.preview {
      width: 100%;
    }

    .tips {
      color: #999;
    }

    .base-info {
      margin-bottom: 10px;

      & > div {
        display: inline-block;

        input {
          padding: 0 10px;
          height: 30px;
          border: 1px solid #999999;
          opacity: 1;
          border-radius: 3px;
        }

        &:not(:last-of-type) {
          margin-right: 20px;
        }
      }
    }
  }

  .btn-save {
    position: absolute;
    bottom: 30px;
    left: 60%;
    height: 40px;
    padding: 0 12px;
    width: 200px;
    color: #fff;
    transform: translateX(-50%);
    font-size: 24px;
  }

  .btn-saves {
    position: absolute;
    bottom: 30px;
    left: 40%;
    height: 40px;
    padding: 0 12px;
    width: 200px;
    color: #fff;
    transform: translateX(-50%);
    font-size: 24px;
  }

  /deep/.custom-marker {
    position: relative;
    text-align: center;
    color: #fff;

    & > div {
      position: absolute;
      top: 2px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 12px;
    }

    .active {
      display: none;
    }

    .icon {
      font-size: 10px !important;
      margin-top: 4px;
    }

    &:hover {
      .active {
        display: block;
      }

      span {
        display: none;
      }
    }
  }
}
</style>
